import { Form, Formik } from "formik"
import React from "react"

import FormCheckbox from "../../../Elements/Form/FormCheckbox"
import ActionButtons from "../../../Elements/ActionButtons"
import Section from "../../../Elements/Section"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons"

const SummaryConsent = ({
  state,
  loading,
  dispatch,
  pageContext,
  handleSubmit,
}) => {
  const TERMS_AND_CONDITIONS_BLURB = `<span>I agree to the <a href="/terms-and-conditions" target="_blank" class="has-text-secondary has-text-weight-bold is-underlined"><b>Terms and Conditions</b></a>.</span>`
  const CONSENT_NOVO_NORDISK_BLURB = `I consent to be engaged by Novo Nordisk Pharmaceuticals (Philippines), Inc. and its service providers via digital channels and mobile phone for updates on our CMEs, promotions, and materials related to our products, patient support, and Saxenda<sup>®</sup> Patient Support Program. <span class="has-text-grey is-italic">(Optional)</span>`

  return (
    <Formik
      initialValues={{ consentAndAuthorization: [], ...state }}
      onSubmit={handleSubmit}
      enableReinitialize={true}
    >
      {({ values }) => (
        <Form>
          <Section title="Consent and Authorization" isSectionRequired={true}>
            <div className="mb-3">
              <FormCheckbox
                name="consentAndAuthorization"
                values={values.consentAndAuthorization}
                options={[TERMS_AND_CONDITIONS_BLURB]}
                onChange={(event) => {
                  dispatch({
                    type: "SAVE_AGREE_CONSENT",
                    payload: event.target.checked
                      ? [TERMS_AND_CONDITIONS_BLURB]
                      : [],
                  })
                }}
                isRequired
              />
              <FormCheckbox
                name="consentNovoNordisk"
                values={values?.consentNovoNordisk}
                options={[CONSENT_NOVO_NORDISK_BLURB]}
                onChange={(event) => {
                  dispatch({
                    type: "SAVE_AGREE_NOVO_NORDISK",
                    payload: event.target.checked
                      ? [CONSENT_NOVO_NORDISK_BLURB]
                      : [],
                  })
                }}
                isRequired
              />
            </div>
          </Section>
          <ActionButtons
            back={{
              label: <FontAwesomeIcon icon={faChevronLeft} />,
              link: pageContext.backPath,
            }}
            submit={{
              loading,
              label: "Submit",
            }}
          />
        </Form>
      )}
    </Formik>
  )
}

export default SummaryConsent
