import { get } from "lodash"
import { navigate } from "gatsby"
import classNames from "classnames"
import React, { useContext, useState, useEffect } from "react"

import Layout from "../../../Layout"
import Section from "../../../Elements/Section"
import Message from "../../../Elements/Message"
import Container from "../../../Layout/Container"
import SummaryConsent from "./SummaryConsent"
import EditDetailsButton from "../../../Elements/EditDetailsButton"
import FileThumbnail from "./FileThumbnail"

import { DoctorEnrollmentContext } from "../../EnrollmentContext/DoctorEnrollmentContext"
import { initialState } from "../../EnrollmentContext/PatientEnrollmentReducer"
import { useAirtableDoctorEnrollmentFormFields } from "../../EnrollmentContext/hooks/useAirtableDoctorEnrollmentFormFields"

import { flattenNode } from "../../../../services/arrays"
import { handleSubmit } from "../../services/handleSubmit"
import removeTags from "../../utils/removeHTMLTags"
let { parseFormField } = require("../../../../services/airtable")

const Summary = ({ pageContext }) => {
  const [loading, setLoading] = useState(false)
  const { doctorEnrollmentState, doctorEnrollmentDispatch } = useContext(
    DoctorEnrollmentContext
  )

  useEffect(() => {
    doctorEnrollmentDispatch({ type: "GET_CONTEXT_FROM_SESSION" })
  }, [doctorEnrollmentDispatch])

  const formFields = flattenNode(
    useAirtableDoctorEnrollmentFormFields()
      .allAirtableallDoctorEnrollmentFormFields
  )

  let sectionSummaryFields = parseFormField(
    pageContext.summaryFields.map((formField) => ({
      ...formField,
      section: formField.section,
    }))
  )
  sectionSummaryFields = sectionSummaryFields.sort(
    (firstSection, secondSection) => firstSection.order - secondSection.order
  )

  return (
    <Layout
      title={pageContext?.module?.title}
      subtitle={pageContext?.module?.subtitle}
      seoTitle={pageContext?.module?.title}
      pageContext={pageContext}
      verifyPage
      isPrivate
      display={{
        footer: false,
        bottomMargin: false,
        progressBar: true,
      }}
    >
      <Container isCentered desktop={6}>
        {sectionSummaryFields.map((section) => {
          return (
            <Section
              title={section?.section}
              addOns={{
                right: <EditDetailsButton route={section.link} />,
              }}
              isSectionRequired={section?.isSectionRequired}
            >
              {section?.section === "Prescription" ? (
                doctorEnrollmentState?.documents?.length > 0 ? (
                  <div className="mx-1">
                    <div className="columns is-vcentered is-multiline">
                      {doctorEnrollmentState?.documents.map((prescription) => {
                        const arr = prescription?.oldname?.split(".")
                        const filetype = arr[arr.length - 1]
                        const isPdf = filetype === "pdf"
                        return (
                          <div className="column is-6">
                            <FileThumbnail file={prescription} isPdf={isPdf} />
                          </div>
                        )
                      })}
                    </div>
                  </div>
                ) : (
                  <Message color="warning">
                    <p className="has-text-black ">
                      {"You have not uploaded any documents."}
                    </p>
                  </Message>
                )
              ) : (
                <div className="table-container">
                  <table class="table is-fullwidth ">
                    <tbody>
                      {section.fields
                        .sort((firstField, secondField) => {
                          return firstField.order - secondField.order
                        })
                        .map((field) => {
                          let finalValue = null
                          switch (field.type) {
                            case "schedule":
                              finalValue = get(
                                doctorEnrollmentState,
                                field.name
                              )
                                ? `${
                                    get(doctorEnrollmentState, field.name)?.day
                                      ?.label
                                  }, ${
                                    get(doctorEnrollmentState, field.name)?.time
                                      ?.label
                                  }`
                                : ""
                              break
                            case "select":
                            case "hospital":
                              finalValue = get(
                                doctorEnrollmentState,
                                field.name
                              )
                                ? get(doctorEnrollmentState, field.name)
                                    ?.value === "Other"
                                  ? get(doctorEnrollmentState, "otherHospital")
                                  : get(doctorEnrollmentState, field.name)
                                      ?.value
                                : ""
                              break
                            case "date":
                              finalValue = get(
                                doctorEnrollmentState,
                                field.name
                              )
                                ? `${
                                    get(doctorEnrollmentState, field.name)
                                      ?.month?.value
                                  } ${
                                    get(doctorEnrollmentState, field.name)?.date
                                      ?.value
                                  } ${
                                    get(doctorEnrollmentState, field.name)?.year
                                      ?.value
                                  }`
                                : ""
                              break
                            case "address":
                              finalValue = get(
                                doctorEnrollmentState,
                                field.name
                              )
                                ? `${
                                    get(doctorEnrollmentState, field.name)
                                      ?.streetAddress
                                  }, ${
                                    get(doctorEnrollmentState, field.name)
                                      ?.province?.value
                                  }, ${
                                    get(doctorEnrollmentState, field.name)?.city
                                      ?.value
                                  }, ${
                                    get(doctorEnrollmentState, field.name)
                                      ?.barangay?.value
                                  }`.trim("")
                                : ""
                              if (field.name === "clinicAddress")
                                finalValue = finalValue.slice(1, -1) //finalValue = finalValue.replaceAll(/[,]/g, "");
                              break
                            case "checkbox":
                              finalValue = get(
                                doctorEnrollmentState,
                                field.name
                              )?.join(", ")
                              break
                            default:
                              finalValue = get(
                                doctorEnrollmentState,
                                field.name
                              )
                              if (
                                field.name === "specialization" &&
                                get(doctorEnrollmentState, field.name) ===
                                  "Other"
                              )
                                finalValue = get(
                                  doctorEnrollmentState,
                                  "otherSpecialization"
                                )
                              if (field.name === "otherSpecialization")
                                //always makes otherSpecialization null
                                finalValue = null
                              if (field.name === "otherHospital")
                                finalValue = null
                              if (field.isContactNumber)
                                finalValue = `+63${finalValue}`
                              break
                          }

                          if (!!finalValue)
                            return (
                              <tr>
                                <td style={{ border: "none" }}>
                                  <h6 className="has-text-weight-bold">
                                    {field.label}
                                  </h6>
                                  <p>{removeTags(finalValue)}</p>
                                </td>
                              </tr>
                            )
                          return null
                        })}
                    </tbody>
                  </table>
                </div>
              )}
            </Section>
          )
        })}
        <SummaryConsent
          loading={loading}
          state={doctorEnrollmentState}
          pageContext={pageContext}
          handleSubmit={async (values, actions) => {
            try {
              setLoading(true)

              if (
                !("consentAndAuthorization" in values) ||
                values?.consentAndAuthorization?.length === 0
              ) {
                actions.setFieldError(
                  "consentAndAuthorization",
                  "This field is required"
                )
                setLoading(false)
              } else {
                await handleSubmit({
                  state: doctorEnrollmentState,
                  formFields,
                  module: pageContext?.module,
                })
                setLoading(false)
                doctorEnrollmentDispatch(initialState)
                navigate(pageContext.nextPath)
              }
            } catch (e) {
              setLoading(false)
            }
          }}
          dispatch={doctorEnrollmentDispatch}
        />
      </Container>
    </Layout>
  )
}

export default Summary
